import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogCardHome from '../components/blogCardHome'
import CustomReactShare from '../components/customShareBlock'
import HomeHeader from '../components/homeHeader'
import aboutBackground from '../../static/newArticleBackground.jpg'
import aboutBackgroundMobile from '../../static/ArticleMobileBackground.jpg'
import { makeStyles } from '@material-ui/core/styles';
import Loader from '../components/loader'
import ComingSoon from '../components/comingSoon'

const useStyles = makeStyles((theme) => ({
  mobile: {
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    },
  },
  desktop: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },
})
)

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  const image = data.site.siteMetadata.siteImage
  const url = data.site.siteMetadata.siteUrl
  const description = data.site.siteMetadata.description
  const imageObject = { "src": image, "height": 400, "width": 1000 }

  const classes = useStyles()

  const [loadingIndicator, setLoadingIndicator] = useState(true)

  return (
    <div>
      {loadingIndicator &&
        <Loader load={loadingIndicator} setLoad={setLoadingIndicator} />
      }
      <div style={
        {
          display: loadingIndicator ? 'none' : 'block'
        }
      }>
        {/* <ComingSoon /> */}
        <div className={classes.desktop} >
          <HomeHeader pic={aboutBackground} text='' />
        </div>
        <div className={classes.mobile} >
          <HomeHeader pic={aboutBackgroundMobile} text='' />
        </div>
        <Layout location={location} title={siteTitle}>
          <SEO title={siteTitle}
            image={imageObject}
            description={description}
            pathname={location.pathname} />
          <BlogCardHome posts={posts} />
          <div>
            <h4
              style={
                {
                  textAlign: 'center',
                  justifyContent: 'center',
                  paddingBottom: '.1%'
                }
              }>
              Share this Website
            <CustomReactShare title={siteTitle} excerpt={description} url={url} text='#blog via @ailuj876' />
            </h4>
          </div>
        </Layout>
      </div>
    </div>
  )
}

export default BlogIndex

export const pageQuery = graphql`
query {
    site {
      siteMetadata {
        title
        siteImage
        description
        siteUrl
      }
    }
    allMarkdownRemark(filter: {
      frontmatter: {tags: {eq: "article"}}
    }sort: {order: DESC, fields: [frontmatter___date]}) {
    edges {
      node {
        excerpt
        fields {
          slug
          readingTime {
            text
          }
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          link
          buttonText
          image: featured {
            childImageSharp {
              resize(width: 1200) {
                src
                height
                width
              }
            }
          }
          tags
        }
      }
    }
  }
}
`
